import { IUnrevealedCard } from './StateModels';

export interface ICard {
  deckId: number;
  value: CardValue;
  suit: CardSuit;
  cardId: string;
}

export type IHandCard = {
  source: PlayerHand.HAND;
  card: ICard;
};

export type IVisibleCard = {
  source: PlayerHand.VISIBLE;
  card: ICard;
};

export type IHiddenCard = {
  source: PlayerHand.HIDDEN;
  card: IUnrevealedCard;
};

export type ISourcedCard = IHandCard | IVisibleCard | IHiddenCard;

export enum PlayerHand {
  HAND = 0,
  VISIBLE = 1,
  HIDDEN = 2,
}

export enum CardValue {
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
  JACK = 11,
  QUEEN = 12,
  KING = 13,
  ACE = 14,
}

export enum CardSuit {
  HEARTS = 0,
  DIAMONDS = 1,
  CLUBS = 2,
  SPADES = 3,
}

export function suitToNamed(suit: CardSuit): string {
  switch (suit) {
    case CardSuit.HEARTS:
      return 'hearts';
    case CardSuit.DIAMONDS:
      return 'diamonds';
    case CardSuit.CLUBS:
      return 'clubs';
    case CardSuit.SPADES:
      return 'spades';
  }
}

export function valueToNamed(value: CardValue): string {
  switch (value) {
    case CardValue.JACK:
      return 'jack';
    case CardValue.QUEEN:
      return 'queen';
    case CardValue.KING:
      return 'king';
    case CardValue.ACE:
      return 'ace';
    default:
      return value.toString();
  }
}
