import { PanInfo, Reorder } from 'framer-motion';
import { Component } from 'react';
import { ICard, IHandCard, ISourcedCard } from '../../../Models/CardModels';
import { ISourcedCardDragTarget } from '../../../Models/UIModels';
import DraggableCard from '../DraggableCard/DraggableCard';

export interface IHandCardsProps {
  handCards: IHandCard[];
  onCardsDragged: (cards: ISourcedCard[], info: PanInfo) => void;
  reorderHandCards: (cards: ICard[]) => void;
  setDragTarget(playAreaDragTarget: ISourcedCardDragTarget): void;
  draggingPlayerCards: (cards: ISourcedCard[], info: PanInfo) => void;
}

class HandCards extends Component<IHandCardsProps> {
  public render() {
    const { handCards } = this.props;
    return (
      <Reorder.Group
        axis="x"
        onReorder={(newOrder) =>
          this.props.reorderHandCards(newOrder.map((c) => c.card))
        }
        values={handCards}
        className={`justify-center px-10 max-h-full max-w-full flex overflow-x-clip`}
      >
        {handCards.map((c, i) => (
          <div className="min-w-10" key={c.card.cardId}>
            <DraggableCard
              card={c}
              onDragEnd={(info) => this.props.onCardsDragged([c], info)}
              onDragging={(info) => this.props.draggingPlayerCards([c], info)}
              setDragTarget={this.props.setDragTarget}
              asReorderItem={true}
            ></DraggableCard>
          </div>
        ))}
      </Reorder.Group>
    );
  }
}

export default HandCards;
