export interface IConfig {
  apiGateway: {
    URL: string;
  };
}

const dev = {
  apiGateway: {
    URL: 'https://serve.tensandtwos.limajuice.com',
  },
};

const prod = {
  apiGateway: {
    URL: 'https://serve.tensandtwos.limajuice.com',
  },
};

const config: IConfig = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...config,
};
