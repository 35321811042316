import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ICard } from '../../../Models/CardModels';
import { IUnrevealedCard } from '../../../Models/StateModels';
import { IDragTarget } from '../../../Models/UIModels';
import BurntCards from '../BurntCards/BurntCards';
import Deck from '../Deck/Deck';
import Pile from '../Pile/Pile';

interface IDeckAndPileProps {
  deckRemaining: IUnrevealedCard[];
  burntCards: IUnrevealedCard[];
  lastPlayedCard: ICard | IUnrevealedCard | undefined;
  playedCards: (ICard | IUnrevealedCard)[];
  setDragTarget(playAreaDragTarget: IDragTarget): void;
}

const DeckAndPile = ({
  deckRemaining,
  lastPlayedCard,
  playedCards,
  burntCards,
  setDragTarget,
}: IDeckAndPileProps) => {
  const [dragHovered, setDragHovered] = useState(false);
  const [dragging, setDragging] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setDragTarget({
      onDragEnter: () => setDragHovered(true),
      onDragExit: () => setDragHovered(false),
      onDragStart: () => setDragging(true),
      onDragEnd: () => setDragging(false),
      getCoords: () =>
        ref?.current
          ? {
              width: ref.current.getBoundingClientRect().width,
              height: ref.current.getBoundingClientRect().height,
              y: ref.current.getBoundingClientRect().top,
              x: ref.current.getBoundingClientRect().left,
            }
          : undefined,
    });
  }, [setDragTarget]);
  return (
    <div className={'flex justify-center'}>
      <div
        className={classNames(
          'drag-target rounded-xl h-full flex relative m-4 p-4 text-lg items-end overflow-visible flex-shrink transition-colors duration-100;',
          {
            'outline-2 outline-dashed outline-[#607d8b]': dragging,
            'bg-[#607d8b50]': dragHovered,
          }
        )}
        ref={ref}
      >
        {deckRemaining.length > 0 && <Deck deckRemaining={deckRemaining} />}
        <Pile playedCards={playedCards} lastPlayedCard={lastPlayedCard} />
        <BurntCards burntCards={burntCards} />
      </div>
    </div>
  );
};

export default DeckAndPile;
