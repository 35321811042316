import { AnimatePresence, motion, PanInfo } from 'framer-motion';
import { memoize } from 'lodash';
import { Component } from 'react';
import {
  ICard,
  IHandCard,
  IHiddenCard,
  ISourcedCard,
  IVisibleCard,
  PlayerHand,
} from '../../Models/CardModels';
import { IVisiblePlayer } from '../../Models/StateModels';
import { ISourcedCardDragTarget } from '../../Models/UIModels';
import HandCards from './HandCards/HandCards';
import './Player.css';
import TableCards from './TableCards/TableCards';

export interface IPlayerProps {
  player: IVisiblePlayer;
  dropPlayerCards: (cards: ISourcedCard[], info: PanInfo) => void;
  draggingPlayerCards: (cards: ISourcedCard[], info: PanInfo) => void;
  tryReorderHandCards: (cards: ICard[]) => Promise<void>;
  tryPickup: () => void;
  setDragTarget(playAreaDragTarget: ISourcedCardDragTarget): void;
  handleError: (e: Error) => void;
}

class Player extends Component<IPlayerProps> {
  public render() {
    const { player } = this.props;
    const { tableCards, handCards, hiddenCards } = getCards(player);
    return (
      <>
        <div className="player-buttons">
          <AnimatePresence>
            {player.handCards.length > 0 && (
              <motion.button
                layout
                key="sort-btn"
                layoutId="sort-btn"
                className="sort-cards"
                onClick={this.onSortButtonClick}
                disabled={!player.isTurn}
              >
                Sort
              </motion.button>
            )}
            {player.canPickup && (
              <motion.button
                layout
                initial={{
                  opacity: 0,
                  scale: 0,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                }}
                exit={{
                  opacity: 0,
                  scale: 0,
                }}
                key="pickup-btn"
                layoutId="pickup-btn"
                className="pickup-cards"
                onClick={this.tryPickupCards}
                disabled={!player.isTurn}
              >
                Pickup
              </motion.button>
            )}
          </AnimatePresence>
        </div>
        <div className="player">
          <TableCards
            visibleCards={tableCards}
            hiddenCards={hiddenCards}
            setDragTarget={this.props.setDragTarget}
            onCardsDragged={this.props.dropPlayerCards}
            draggingPlayerCards={this.props.draggingPlayerCards}
          />
          <HandCards
            handCards={handCards}
            setDragTarget={this.props.setDragTarget}
            onCardsDragged={this.props.dropPlayerCards}
            draggingPlayerCards={this.props.draggingPlayerCards}
            reorderHandCards={this.props.tryReorderHandCards}
          />
        </div>
      </>
    );
  }

  private onSortButtonClick = () => {
    this.props.tryReorderHandCards(
      [...this.props.player.handCards].sort((a, b) => a.value - b.value)
    );
  };

  private tryPickupCards = () => {
    this.props.tryPickup();
  };
}

export default Player;

const getCards = memoize((player: IVisiblePlayer) => {
  const tableCards: IVisibleCard[] = player.visibleCards.map(
    (card) => ({ source: PlayerHand.VISIBLE, card }) as const
  );
  const handCards: IHandCard[] = player.handCards.map(
    (card) => ({ source: PlayerHand.HAND, card }) as const
  );
  const hiddenCards: IHiddenCard[] = player.hiddenCards.map(
    (card) => ({ source: PlayerHand.HIDDEN, card }) as const
  );
  return { tableCards, handCards, hiddenCards };
});
